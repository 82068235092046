import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FooldalService } from 'src/app/services/fooldal.service';

@Component({
  selector: 'app-sikeres-kurzus',
  templateUrl: './sikeres-kurzus.component.html',
  styleUrls: ['./sikeres-kurzus.component.css']
})
export class SikeresKurzusComponent {
  toPayProduct: any[] = [];
  profileCustomer: any[] = [];
  simpleTransactionId: string | null = null;
  tries: number = 0;
  latestOrderId: string | null = null;
  backUrl: string | null = null;
  backPage: string | null = null;
  boughtProductType: string | null = null;

  constructor(private fooldalService: FooldalService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    const simple = this.route.snapshot.queryParamMap.has('r');
    if(simple) {
      this.processSimplePay();
    } else {
      this.processPayPal();
    }
    this.latestOrderId = localStorage.getItem('latestOrderId');
    this.backUrl = localStorage.getItem('teremben') === 'true' ? '/foglalkozasok-teremben' : '/elofizetes';
    this.backPage = localStorage.getItem('teremben') === 'true' ? 'Foglalkozások teremben' : 'Előfizetéseim';
    this.boughtProductType = localStorage.getItem('teremben') === 'true' ? 'Foglalkozás amire jelentkeztél' : 'Megrendelt kurzus';
    localStorage.removeItem('teremben');
  }

  private processSimplePay() {
    const r = this.route.snapshot.queryParamMap.get('r')!;
    const s = this.route.snapshot.queryParamMap.get('s')!;
    const response = JSON.parse(atob(r));

    this.simpleTransactionId = response.t;
    const obj = {
      title: '',
      price: ''
    }
    if (localStorage.getItem('product')) {
      var current = JSON.parse(localStorage.getItem('product')!)
    }
    obj.title = current.title;
    obj.price = current.price;
    this.toPayProduct.push(obj)

    var localPId = localStorage.getItem('productId');

    this.fooldalService.getSimplePayGatewayData().subscribe(gw => {
      const data = gw.data[0];

    const paymentBody = {
      "data":
        {
          "type": "order--default",
          "id": response.o,
          "attributes": {
            "payment_instrument": {
              "payment_gateway_id": data.attributes.label,
              "payment_method_type": "credit_card",
              "payment_gateway_mode": data.attributes.configuration.mode,
            }
          }
        }
    }


    //this.tryLicenseAndRedirect(localPId);
    this.tryOrderPaidAndRedirect(this.latestOrderId);

    /*
    this.fooldalService.addPaymentMethod(paymentBody, localPId).subscribe( p => {
        this.fooldalService.approvePaymentSimple(localPId, r , s).subscribe( f => {
        });
      });
      */
  });
  }

  private tryOrderPaidAndRedirect(orderId:any) {
    if(this.tries++ > 60) {
      console.error('Tried 60 times, but no order payment found');
      return;
    }
    this.fooldalService.getOrderPayment(orderId).subscribe( (order:any) => {
      if(order.data[0]?.attributes.state == 'completed') {
        this.router.navigateByUrl(this.backUrl!);
      } else {
        setTimeout(() => {
          this.tryOrderPaidAndRedirect(orderId);
        }, 1000);
      }
    });
  };

  private tryLicenseAndRedirect(orderId:any) {
    if(this.tries++ > 60) {
      console.error('Tried 60 times, but no license found');
      return;
    }
    this.fooldalService.licenseForOrderId(orderId).subscribe( (license:any) => {
      if(license.data[0]?.state == 'active') {
        this.router.navigateByUrl(this.backUrl!);
      } else {
        setTimeout(() => {
          this.tryLicenseAndRedirect(orderId);
        }, 1000);
      }
    });
  };


  private processPayPal() {
    const obj = {
      title: '',
      price: ''
    }
    if (localStorage.getItem('product')) {
      var current = JSON.parse(localStorage.getItem('product')!)
    }
    obj.title = current.title;
    obj.price = current.price;
    this.toPayProduct.push(obj)

    var localPId = localStorage.getItem('productId');
    var paypalId = localStorage.getItem('paypalApi');

    this.fooldalService.getPaypalGatewayData().subscribe(gw => {
      const data = gw.data[0];

    const paymentBody = {
      "data":
        {
          "type": "order--default",
          "id": localPId,
          "attributes": {
            "payment_instrument": {
              "payment_gateway_id": data.attributes.label,
              "payment_method_type": "paypal_checkout",
              "payment_gateway_mode": data.attributes.configuration.mode,
              "payment_details": {
                "paypal_remote_id": paypalId
              }
            }
          }
        }
    }

    const startBody = {
      "data": {
        "type": "payment--paypal-checkout",
        "attributes": {
          "capture": true
        }
      }
    }


    this.fooldalService.addPaymentMethod(paymentBody, localPId).subscribe( p => {
      this.fooldalService.startPayment(startBody, localPId).subscribe( f => {
        setTimeout(() => {
          this.router.navigateByUrl(this.backUrl!);
        }, 5000);
      })
    });
  });
  }
}

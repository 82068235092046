<div class="wrapper mx-auto">
    <div class="arrow-steps clearfix">
        <div class="step"> <span>Adatok</span> </div>
        <div class="step"> <span>Összegzés</span> </div>
        <div class="step current"><span> Véglegesítés</span> </div>
    </div>
</div>
<div class="message m-5 p-3 mx-auto" style="border-radius: 6px;">
    <span>{{text}}</span>
    <div *ngIf="simpleTransactionId">SimplePay tranzakció azonosító: {{simpleTransactionId}}</div>
    <div *ngIf="subText" class="mt-2">
        {{subText}}
    </div>
</div>
<div class="m-5">
    <div class="d-flex justify-content-center">
        <button routerLink="/elofizetes" class="btn btn-primary m-3 p-2">Vissza az előfizetéseim oldalra</button>
    </div>
</div>

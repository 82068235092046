<div class="wrapper mx-auto">
    <div class="arrow-steps clearfix">
        <div class="step"> <span>Adatok</span> </div>
        <div class="step"> <span>Összegzés</span> </div>
        <div class="step current"><span> Véglegesítés</span> </div>
    </div>
</div>
<div class="text-center message m-5 p-3 mx-auto rounded">
    Sikeres megrendelés.<br/>
    <b>A fizetési szolgáltató válaszától függően (ami akár egy perc is lehet) hamarosan átirányítunk az {{backPage}} oldalra.</b><br/>
    Számládat hamarosan e-mailben küldjük.
    <div class="mt-2" *ngIf="simpleTransactionId">
      SimplePay tranzakció azonosító: {{simpleTransactionId}}
    </div>
</div>
<div class="text-center m-5" *ngFor="let product of toPayProduct">
    <p class="cart-content">{{boughtProductType}}</p>
    <span class="course-name">{{product.title}}</span>
</div>

<div class="c_title">
  <p>{{ courseTitle }}</p>
</div>
<div class="container">
  <ng-container *ngFor="let blockt of block; let i = index">
    <div class="d-flex flex-column course mb-3 position-relative" *ngIf="blockt.tema_title || blockt.tema_description">
      <div class="d-flex justify-content-between align-items-center">
        <span class="course-title" (click)="toggleLesson(i)" *ngIf="blockt.tema_title">{{ blockt.tema_title }}</span>
        <button type="button" class="btn btn-primary button-style d-flex align-items-center justify-content-center gap-2" style="width:auto;padding:6px 12px;" (click)="toggleLesson(i)"><fa-icon [icon]="lessonStates[i] ? upArrow : downArrow" class="down-arrow"></fa-icon></button>
      </div>


      <div class="container" *ngIf="blockt.tema_description" style="display:flex;">
        <ng-container *ngIf="blockt.image_url">
          <div *ngIf="blockt.image_url" style="margin-right:20px;">
            <img [src]="blockt.image_url" style="width:300px;" />
          </div>
          <p class="course-desc" style="flex-grow:1" [class.collapsedDesc]="!lessonStates[i]" (click)="toggleLesson(i)" [innerHTML]="blockt.tema_description"></p>
        </ng-container>
        <ng-container *ngIf="!blockt.image_url">
          <p class="course-desc" [class.collapsedDesc]="!lessonStates[i]" (click)="toggleLesson(i)" [innerHTML]="blockt.tema_description"></p>
        </ng-container>

      </div>
      <div class="lessons" [ngClass]="{'expanded': lessonStates[i]}" [@expandCollapse]="lessonStates[i] ? 'expanded' : 'collapsed'">
        <div class="lesson" *ngFor="let lesson of blockt.lessons; let k = index">
          <div class="lesson-container">
            <div class="video-container">
              <div *ngIf="lessonStates[i] && lesson.video_url && !lesson.y_video_url && !lesson.image_url" class="lesson-img-video" (click)="openModalIfNotCompleted(lesson)">
                <app-video-player [ngClass]="{'disabled-button': !visible}" style="padding-top: 15px;"
                  [options]="{fluid: true,  aspectRatio: '16:9', autoplay: false, poster: lesson.thumbnail, sources: lesson.sources}" [lesson]="lesson" [lessonId]="lesson.lessonId" [fulfillmentId]="lesson.fulfillment_uuid">
                </app-video-player>
              </div>
              <div *ngIf="lessonStates[i] && lesson.y_video_url && !lesson.video_url && !lesson.image_url" class="youtube-video">
                <iframe [src]="lesson.y_video_url | kurzussafe : 'resourceUrl'" frameborder="0"
                  class="iframe-size"></iframe>
              </div>
              <div *ngIf="lesson.image_url">
                <img [src]="lesson.image_url" (click)="imgNav(lesson)" class="lesson-img-video" [class.lesson-img-clickable]="lesson.image_link && visible" />
              </div>
            </div>
            <div class="lesson-info" #lessonInfo (click)="openModalIfNotCompleted(lesson);onLessonInfoClick($event, lesson, lesson.fulfillment_uuid)">
              <div class="d-flex justify-content-between align-items-center">
                <h3 [class]="getLessonColor(lesson)">{{ lesson.lessons_title }}</h3>
                <ng-container *ngIf="(lesson.video_url && !lesson.y_video_url && !lesson.image_url && !lesson.disabled)">
                  <button type="button" class="like-button" (click)="toggleLike(lesson.mid)">
                    <fa-icon style="display: flex;" [icon]="lesson.isItFavorite ? faSolidHeart : faRegularHeart"></fa-icon>
                  </button>
                </ng-container>
              </div>
              <div [innerHTML]="lesson.lessons_desc" class="hidden-measure-container" #measureContainer></div>
              <div #content [ngClass]="{'disabled-button': !visible}" [innerHTML]="lesson.lessons_desc" [class.truncated-text]="lesson.isTextOverflow"></div>
              <p class="read-more-button text-right" *ngIf="isTextOverflowing(lesson, measureContainer)" (click)="$event.preventDefault();$event.stopPropagation(); toggleTextOverflow(lesson)">{{ lesson.isTextOverflow ? 'TOVÁBB' : 'KEVESEBB' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="overlay" *ngIf="showModal"></div>
  <div class="modal fade modalc" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
      id="exampleModal">
      <div class="modal-dialog" role="document">
          <div class="modal-content">
              <form>
                  <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
                      <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
                          (click)="closeModal()">
                      </button>
                  </div>
                  <div class="modal-body">
                      <div>{{ modalContent }}</div>
                  </div>
                  <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" data-dismiss="modal"
                          (click)="closeModal()">Bezárás</button>
                  </div>
              </form>
          </div>
      </div>
  </div>
</div>

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { faPauseCircle, faPlayCircle, faWindowMaximize, faWindowRestore } from '@fortawesome/free-regular-svg-icons';
import { Observable, map, tap } from 'rxjs';
import { FooldalService } from 'src/app/services/fooldal.service';
import { VideoStatusService } from 'src/app/services/video-status.service';
import videojs from 'video.js';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css'],
})
export class VideoPlayerComponent {
  faPlay = faPlayCircle;
  faPause = faPauseCircle;
  faWindow = faWindowMaximize;
  faWindowRestore = faWindowRestore;

  constructor(private videoStatusService: VideoStatusService, private breakpointObserver: BreakpointObserver, private renderer: Renderer2, private el: ElementRef, private fooldalService: FooldalService) {}

  @ViewChild('target', { static: true }) target!: ElementRef;

  @Input() options?: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    poster: string;
    controlBar?: videojs.ControlBarOptions;
    sources: {
      src: string;
      type: string;
      label: string;
    }[];
  };
  @Input() paddingTop: string = '0px';
  @Input() lessonId: string = '';
  @Input() fulfillmentId: string = '';
  @Input() lesson: any;
  player!: videojs.Player;
  isTablet$!: Observable<boolean>;

  ngOnInit() {
    const self = this;
    this.isTablet$ = this.breakpointObserver.observe(Breakpoints.TabletPortrait)
    .pipe(
      map(result => result.matches),
      map(_ => false),
      tap(isTablet => {
      }));
      if(this.options) {
        this.options.controlBar = {
          playToggle: true,
          fullscreenToggle: true,
          pictureInPictureToggle: true,
          progressControl: {
            seekBar: true
          },
          volumePanel: true
        }
      }
      if(this.player) {
        this.player.dispose();
      }
      const o = this.options;
      this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
        self.videoStatusService.addPlayer(this, self.lessonId);
        // Ellenőrizd, hogy van-e már minőségváltó a control bar-ban
        let qualitySelector = this.controlBar.el().querySelector('select');

        if (!qualitySelector) {
          // Ha nincs, akkor hozz létre egy select elemet a minőségváltáshoz
          qualitySelector = document.createElement('select');
          qualitySelector.style.background = 'transparent';
          qualitySelector.style.border = 'none';
          qualitySelector.style.color = 'white';

          // Adj hozzá a control bar-hoz
          this.controlBar.el().appendChild(qualitySelector);

          // Eseménykezelő a minőségváltásra
          o!.sources?.forEach((source: any) => {
            const option = document.createElement('option');
            option.value = source.src;
            option.text = source.label;
            option.style.background = '#254350';
            if(qualitySelector){
              qualitySelector.appendChild(option);
            }
          });

          qualitySelector.addEventListener('change', (event: any) => {
            const selectedSrc = event.target.value;
            this.src(selectedSrc);
          });
        }

        this.on('play', () => {
          if(self.lessonId) {
            self.videoStatusService.pauseAllOtherPlayers(self.lessonId);
          }

        });

        this.on('loadedmetadata', () => {
          // Eseménykezelő a minőségváltásra (opcionális, ha külön minőségváltó eseménykezelőre van szükség)
        });

        this.on('timeupdate', () => {
          if(self.lesson) {
            var duration = this.duration();
            var currentTime = this.currentTime();
            var percentageWatched = (currentTime / duration) * 100;

            if (percentageWatched >= 80) {
              this.off('timeupdate');
              if(!self.lesson.completed) {
                self.markDone();
              }
            }
          }
        });
      });
  }

  private findAncestorByClass(element: HTMLElement, className: string): HTMLElement | null {
    while (element && !element.classList.contains(className)) {
      element = element.parentElement!;
    }
    return element;
  }

  private markDone(): void {
    const watchedBody = {
      "data": {
        "type": "course_object_fulfillment--course_object_fulfillment",
        "id": this.fulfillmentId,
        "attributes": {
          "complete": true
        }
      }
    }
    this.fooldalService.patchVideoWatched(watchedBody, this.fulfillmentId).subscribe(() => {
     //window.location.reload();

      this.lesson.completed = true;
      this.videoStatusService.markLessonFulfilled(this.fulfillmentId);
      // set current to done
      const currentNextHeading = this.el.nativeElement.querySelector('.next');
      this.renderer.setAttribute(currentNextHeading, 'class', '');
      this.renderer.addClass(currentNextHeading, 'done');


      const firstDisabledButton = this.el.nativeElement.querySelector('.disabled-button');
      if (firstDisabledButton) {
        const lessonContainer = this.findAncestorByClass(firstDisabledButton, 'lesson-container');
        const currentNext = this.el.nativeElement.querySelector('.next');
        if (currentNext) {
          this.renderer.removeClass(currentNext, 'next');
          this.renderer.addClass(currentNext, 'done');
        }

        if (lessonContainer) {
          const heading = lessonContainer.querySelector('h3');
          this.renderer.setAttribute(heading, 'class', '');
          this.renderer.addClass(heading, 'next');
          this.renderer.removeClass
          const disabledButtons = lessonContainer.querySelectorAll('.disabled-button');
          disabledButtons.forEach(button => {
            this.renderer.removeClass(button, 'disabled-button');
          });
        }
      }

    })
  }

  playPause(): void {
    this.player.paused() ? this.player.play() : this.player.pause();
  }

  fullScreen(): void {
    this.player.requestFullscreen();
  }

  pip(): void {
    this.player.requestPictureInPicture();
  }

  get playing(): boolean {
    return !this.player.paused();
  }

  ngOnDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  }
}

import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { tap, timeout } from 'rxjs';
import { FooldalService } from 'src/app/services/fooldal.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth.service';
import { faLanguage } from '@fortawesome/free-solid-svg-icons';
import { add } from 'date-fns';
import { parseHostBindings } from '@angular/compiler';

@Component({
  selector: 'app-osszegzes',
  templateUrl: './osszegzes.component.html',
  styleUrls: ['./osszegzes.component.css']
})
export class OsszegzesComponent {
  toPayProduct: any[] = [];
  profileCustomer: any[] = [];
  isPinkFill1: boolean = false;
  isPinkFill2: boolean = false;
  orderId: string = '';
  cuoponPrice: any[] = [];
  coupons: any[] = [];
  paypal: boolean = false;
  firstNameControl: FormControl = new FormControl('', [Validators.required]);
  lastNameControl: FormControl = new FormControl('', [Validators.required]);
  postalCodeControl: FormControl = new FormControl('', [Validators.required]);
  cityControl: FormControl = new FormControl('', [Validators.required]);
  addressControl: FormControl = new FormControl('', [Validators.required]);
  houseNumberControl: FormControl = new FormControl('', [Validators.required]);
  countryList: {key: string, value: string}[] = [];
  latestCartId?: string;
  objProfile: any;

  constructor(private fooldalService: FooldalService, private authService: AuthService) { }

  toggleColors(circleNumber: number) {
    if (circleNumber === 1) {
      this.isPinkFill1 = true;
      this.isPinkFill2 = false;
    } else if (circleNumber === 2) {
      this.isPinkFill2 = true;
      this.isPinkFill1 = false;
    }
  }

  onSimplePayClick() {
    this.paypal = false;
  }

  onPayPalClick() {
    this.paypal = true;
  }

  Pay() {
    this.firstNameControl.markAsTouched();
    this.lastNameControl.markAsTouched();
    this.postalCodeControl.markAsTouched();
    this.cityControl.markAsTouched();
    this.addressControl.markAsTouched();
    this.houseNumberControl.markAsTouched();
    localStorage.setItem('latestOrderId', this.latestCartId!);
    if (this.paypal) {
      const body = new HttpParams().set('grant_type', 'client_credentials');
      this.fooldalService.getPayPalAccesToken(body.toString()).subscribe(p => {
        for (const [key, value] of Object.entries(p)) {

          if (key === "access_token") {
            var accessToken = value;
            for (let i in this.toPayProduct) {
              var productTitle = this.toPayProduct[i].title;
            }
            for(let j in this.cuoponPrice){
              var productPrice = this.cuoponPrice[j].current;
              var withoutSpace  = productPrice.replace(/\s+/g, '').replace('Ft', '');
              var convertedPrice = withoutSpace.replace(',', '.');
            }
            const price = Math.trunc(this.cuoponPrice[0].current_number);

                      const createPayPal: any = {
                        "intent" : "CAPTURE",
                        "purchase_units" : [
                          {
                            "items" : [
                              {
                                "name" : this.toPayProduct[0].title,
                                "description" : this.toPayProduct[0].title,
                                "quantity" : "1",
                                "unit_amount" : {
                                  "currency_code" : "HUF",
                                  "value" : price
                                }
                              }]
                            ,
                            "amount" : {
                              "currency_code" : "HUF",
                              "value" : price,
                              "breakdown" : {
                                "item_total" : {
                                  "currency_code" : "HUF",
                                  "value" : price,
                                }
                              }
                            }
                          }
                        ],
                        "application_context" : {
                          "return_url" : environment.webBaseUrl + "/sikeres_fizetes",
                          "cancel_url" : environment.webBaseUrl + "/sikertelen_fizetes"
                        }
                      }

                      /*
                      if(this.toPayProduct[0].sku === 'VThosszabbítás') {
                        createPayPal['payment_source'] = {
                          'paypal': {
                            'attributes': {
                              'vault': {
                                "store_in_vault": "ON_SUCCESS",
                                "usage_type": "MERCHANT"
                              }
                            }
                          }
                        }
                      }
                        */
                      /*
                      this.fooldalService.getPaypalOrder('3GB51037X97922218', accessToken).subscribe( (p:any) => {
                      });
                      return;
                      */
                      this.fooldalService.createPayPalOrder(createPayPal, accessToken).subscribe( (p:any) => {

                        for(const [kk, vvalue] of Object.entries(p)){
                          const vv = vvalue as any;
                          if(kk === 'id'){
                            localStorage.setItem('paypalApi', vv);
                          }
                          if(kk === 'links'){
                            for(let k in vv){
                              window.location.href = vv[1].href;

                            }
                          }
                        }
                      });
                    }
                  }
                });

  } else {
      for (let i in this.toPayProduct) {
        var productTitle = this.toPayProduct[i].title;
      }
      for(let j in this.cuoponPrice){
        var productPrice = this.cuoponPrice[j].current;
        var withoutSpace  = productPrice.replace(/\s+/g, '').replace('Ft', '');
        var convertedPrice = withoutSpace.replace(',', '.');
      }
      const price = Math.trunc(this.cuoponPrice[0].current_number);
      this.fooldalService.getSimplePayGatewayData().subscribe(p => {
        const gw = p.data[0];
        const merchant = p.data[0].attributes.configuration.merchant_id;
        const salt = this.generateRandomAlphanumericString(32);
        const d = new Date();
        d.setMinutes(d.getMinutes() + 15);
        const timeout = this.formatDateWithTimezoneOffset(d);

        const y = new Date();
        y.setFullYear(y.getFullYear() + 1);
        const tokenTimeuot = this.formatDateWithTimezoneOffset(y);
        let request = {
          salt,
          merchant,
          orderRef: this.latestCartId,
          currency: 'HUF',
          customerEmail: this.objProfile.email,
          language: 'HU',
          sdkVersion: 'babafeszkelo-1.0',
          methods:["CARD"],
          total: price,
          timeout,
          urls:{
            success: environment.webBaseUrl + "/sikeres_fizetes",
            fail: environment.webBaseUrl + "/sikertelen_fizetes",
            cancel: environment.webBaseUrl + "/sikertelen_fizetes",
            timeout: environment.webBaseUrl + "/sikertelen_fizetes"
          },
          recurring:{
            times:24,
            until:tokenTimeuot,
            maxAmount:500
          },
          invoice: {
            name: this.firstNameControl.value + ' ' + this.lastNameControl.value,
            company: '',
            country: 'HU',
            city: this.cityControl.value,
            zip: this.postalCodeControl.value,
            address: this.addressControl.value + ' ' + this.houseNumberControl.value,
            address2: '',
            phone: '',
          }
        };
        const merchantKey = p.data[0].attributes.configuration.secret_key;
        const message = JSON.stringify(request);

        const checkout = {
          "data" : {
              "type" : "order--default",
              "id" : this.latestCartId,
              "attributes" : {
                  "billing_information": {
                      "address": {
                          "country_code": this.objProfile.country,
                          "locality": this.cityControl.value,
                          "postal_code": this.postalCodeControl.value,
                          "address_line1": this.addressControl.value,
                          "address_line2": "",
                          "given_name": this.firstNameControl.value,
                          "family_name": this.lastNameControl.value
                      },
                      "tax_number": {
                          "type": null,
                          "value": null
                      }
                  }
              },
            "relationships": {
              "data": {
                "type": "checkout-flow",
                "id": "585db1b4-c23e-4ee4-a32c-df3a7f0a7ae3"
              }
            }
          }
      };

        this.fooldalService.sendToCheckout(checkout, this.latestCartId).subscribe((p:any) => {

          const payment = {
            "data":
              {
                "type": "order--default",
                "id": this.latestCartId,
                "attributes": {
                  "payment_instrument": {
                    "payment_gateway_id": gw.attributes.label,
                    "payment_method_type": "credit_card",
                    "payment_gateway_mode": gw.attributes.configuration.mode
                  }
                }
              }
          }
          this.fooldalService.addPaymentMethod(payment, this.latestCartId).subscribe((p:any) => {
            this.hmacWithSha384Base64(merchantKey, message).then((signature) => {
              //console.warn(message);
              //console.warn(signature);
              this.fooldalService.startSimplePayPayment(this.latestCartId!).subscribe((p:any) => {
                window.location.href = p.payment_url
              });
            });
          });
        });
      });
  }
}

 formatDateWithTimezoneOffset(date: Date) {
  const pad = (num: any) => String(num).padStart(2, '0');

  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1); // Months are zero-based
  const day = pad(date.getDate());
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  const seconds = pad(date.getSeconds());

  // Get timezone offset in minutes and convert to hours and minutes
  const timezoneOffsetMinutes = date.getTimezoneOffset();
  const offsetSign = timezoneOffsetMinutes <= 0 ? '+' : '-';
  const offsetHours = pad(Math.floor(Math.abs(timezoneOffsetMinutes) / 60));
  const offsetMinutes = pad(Math.abs(timezoneOffsetMinutes) % 60);

  // Construct the timezone offset string
  const timezoneOffset = `${offsetSign}${offsetHours}:${offsetMinutes}`;

  // Combine all parts into the desired format
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneOffset}`;
}

generateRandomAlphanumericString(length: number) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  for (let i = 0; i < length; i++) {
    result += chars[array[i] % chars.length];
  }
  return result;
}

async hmacWithSha384Base64(merchantKey: string, message: string) {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(merchantKey);
  const msgData = encoder.encode(message);

  // Import the key
  const cryptoKey = await window.crypto.subtle.importKey(
    'raw',
    keyData,
    { name: 'HMAC', hash: { name: 'SHA-384' } },
    false,
    ['sign']
  );

  // Sign the message
  const signature = await window.crypto.subtle.sign('HMAC', cryptoKey, msgData);

  // Convert ArrayBuffer to Base64
  const hashArray = Array.from(new Uint8Array(signature));
  const hashString = String.fromCharCode(...hashArray);
  const hashBase64 = btoa(hashString);

  return hashBase64;
}

  ngOnInit() {

    this.fooldalService.getCountryCode().pipe(
      tap(c => {
        let countryArray = [];
        for (const [k, v] of Object.entries(c)) {
          if (k === 'available-countries') {
            for (const [key, value] of Object.entries(v)) {
              countryArray.push({key, value: value as string});
            }
          }
        }
        this.countryList = countryArray;
      })
    ).subscribe();

    this.fooldalService.getCurrentCart().subscribe((c: any) => {

      const latestCart = c.data[c.data.length - 1];
      this.latestCartId = latestCart.id;

      latestCart.order_items.forEach((item: any) => {
        this.toPayProduct.push({
          title: item.purchased_entity.field_variation_name,
          price: item.purchased_entity.price.formatted,
          price_number: item.total_price.number,
          sku: item.purchased_entity.sku,
        });
      });
      if(Array.isArray(latestCart.coupons) && latestCart.coupons.length > 0){
      latestCart.coupons?.forEach((coupon: any) => {
        this.fooldalService.getPromotionById(coupon.promotion_id.id).subscribe((p: any) => {
          this.coupons.push({
            name: coupon.code + ' kupon',
            price: -1 * p.data.attributes.offer.target_plugin_configuration.amount.number + ' Ft'
          });
        });

      });
    }
    });

    const objProductPrice = {
      cuopon_price: '',
      cuopon_name: '',
      current: '',
      current_number: ''
    }


    this.authService.getCurrentUser().subscribe(p => {
      const objProfile = {
        id: '',
        name: '',
        country: '',
        county: '',
        postal_code: '',
        city: '',
        address: '',
        email: ''
      };

      let objId = ''; // Új változó az obj.id helyett
      const valuee = p.data[0];
                objProfile.name = valuee.attributes.name;
                objProfile.email = valuee.attributes.mail;
                  var mail = objProfile.email;
                  objId = valuee.id;
                  this.fooldalService.getProfileCustomer().subscribe(p => {
                    for (const [key, value] of Object.entries(p)) {
                      if (key === 'data') {
                        for (let i in value) {
                          objProfile.id = value[i].relationships.uid.data.id;
                          if (objProfile.id === objId) { // Az új változót használjuk itt
                            objProfile.name = value[i].attributes.address.family_name + " " + value[i].attributes.address.given_name;
                            this.firstNameControl.setValue(value[i].attributes.address.given_name);
                            this.lastNameControl.setValue(value[i].attributes.address.family_name);
                            objProfile.country = value[i].attributes.address.country_code;
                            objProfile.postal_code = value[i].attributes.address.postal_code;
                            this.postalCodeControl.setValue(value[i].attributes.address.postal_code);
                            objProfile.city = value[i].attributes.address.locality;
                            this.cityControl.setValue(value[i].attributes.address.locality);
                            objProfile.address = value[i].attributes.address.address_line1 + " " + value[i].attributes.address.address_line2;
                            this.addressControl.setValue(value[i].attributes.address.address_line1);
                            this.houseNumberControl.setValue(value[i].attributes.address.address_line2);
                            this.postalCodeControl.disable();
                            this.houseNumberControl.disable();
                            this.cityControl.disable();
                            this.addressControl.disable();
                            this.firstNameControl.disable();
                            this.lastNameControl.disable();
                            objProfile.email = mail;
                          }
                        }
                      }
                    }
                    this.objProfile = objProfile;
                    this.profileCustomer.push(objProfile);
                  });
    });
    const pIdLocalS = localStorage.getItem('productId');
    if (pIdLocalS !== null) {
      this.orderId = pIdLocalS;
    }
    this.fooldalService.getProductById(this.orderId).subscribe((order) => {
      for (const [key, value] of Object.entries(order)) {
        if (key === 'data') {
          objProductPrice.current = value.order_total.total.formatted;
          objProductPrice.current_number = value.order_total.total.number;
          if (value.order_total.adjustments.length > 0) {
            objProductPrice.cuopon_name = value.order_total.adjustments[0].label;
            objProductPrice.cuopon_price = value.order_total.adjustments[0].amount.formatted;
          }
          this.cuoponPrice.push(objProductPrice);
        }
      }
    });

  }

  get isRecurring() {
    return this.toPayProduct.some(p => p.sku === 'VThosszabbítás' || p.sku === 'VT001' || p.sku === 'VT001F');
  }

}

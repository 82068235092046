import { Component, ElementRef, HostListener, Input, SimpleChanges, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FooldalService } from 'src/app/services/fooldal.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  @Input() menus: any;
  @Input() loggedUser: any;
  @Input() showImage?: boolean;
  @Input() isAdmin?: boolean;
  _displayName: string = '';
  enrolledCourse: any[] = [];
  subscriber: boolean = false;
  videostore: boolean = false;

  @ViewChild('navbarCollapse') navbarCollapse!: ElementRef;
  private bsCollapse: any;

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: Event) {
    const targetElement = event.target as HTMLElement;

    // Check if the click is outside the navbar-collapse element and navbar-toggler button
    if (
      this.navbarCollapse.nativeElement.classList.contains('show') &&
      !this.eRef.nativeElement.contains(targetElement)
    ) {
      this.bsCollapse.hide();
    }
  }

  constructor(
    private router: Router,
    private authService: AuthService,
    private fooldalService: FooldalService,
    private eRef: ElementRef
  ) { }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event) {

    const checkbox = this.eRef.nativeElement.querySelector('.dd-input') as HTMLInputElement;

    if (checkbox && !this.eRef.nativeElement.contains(event.target)) {
      checkbox.checked = false; // Uncheck the dropdown toggle
    }
  }

  ngAfterViewInit() {
    this.bsCollapse = new (window as any).bootstrap.Collapse(this.navbarCollapse.nativeElement, {
      toggle: false, // Prevents automatic toggling on initialization
    });
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.navbarCollapse.nativeElement.classList.contains('show')) {
          this.bsCollapse.hide();
        }
      });
  }
  isActiveMenu(menuPath: string) {
    return this.router.isActive(menuPath, true);
  }

  Logout() {
    const token = localStorage.getItem('login');
    if (token) {
      var login = JSON.parse(token);
      this.authService.fetchCsrfToken().subscribe((csrfToken: string) => {
        if (csrfToken) {
          this.authService.logout(login.logout_token, csrfToken).subscribe(logout => {
            window.location.reload();
          });
        }
      }, (error) => {

      });
    }
    this.router.navigateByUrl("/fooldal");
    localStorage.removeItem('product');
    localStorage.removeItem('display_name');
    localStorage.removeItem('user_id');
    localStorage.removeItem('api-key');
  }

  ngOnInit() {
    this.fooldalService.enrolledCourseLicens().subscribe((s:any) => {
      s.data.forEach((element:any) => {
        if (element.state === 'active') {
        const enrolledCourseDetails = {
          title: '',
          id: '',
          videostore: false
        }
        if (element.product_variation.product_id.type === 'product--videostore') {
          this.videostore = true;
        } else {
          enrolledCourseDetails.title = element.product_variation.product_id.field_course.title;
          enrolledCourseDetails.id = element.product_variation.product_id.field_course.id;

          if(!this.enrolledCourse.some(e => e.id === enrolledCourseDetails.id)) {
            this.enrolledCourse.push(enrolledCourseDetails);
          }
        }
      }
      });
    });
    /*
    this.fooldalService.enrolledUser().subscribe( enrolled => {
      for(const [key, value] of Object.entries(enrolled)){
        if(key === 'courses'){
          for(let i in value){
            const enrolledCourseDetails = {
              title: '',
              cid: ''
            }
            enrolledCourseDetails.title = value[i].title[0].value;
            enrolledCourseDetails.cid = value[i].cid[0].value;

            //this.enrolledCourse.push(enrolledCourseDetails);
          }
        }
      }
    })*/
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['loggedUser'] && localStorage.getItem('login')) {
      this.fooldalService.enrolledCourseLicens().subscribe((s:any) => {
        s.data.forEach((element:any) => {
          if (element.state === 'active') {
          const enrolledCourseDetails = {
            title: '',
            id: '',
            videostore: false
          }
          if (element.product_variation.product_id.type === 'product--videostore') {
            this.videostore = true;
          } else {
            enrolledCourseDetails.title = element.product_variation.product_id.field_course.title;
            enrolledCourseDetails.id = element.product_variation.product_id.field_course.id;

            if(!this.enrolledCourse.some(e => e.id === enrolledCourseDetails.id)) {
              this.enrolledCourse.push(enrolledCourseDetails);
            }
          }
        }
        });
      });
      this.authService.getCurrentUser().subscribe(s => {
        if(s.data.length > 0) {
        const value = s.data[0];
              const user = {
                name: '',
                roles: ''
              };
              user.name = value.attributes.name;
              for (let j in value.relationships) {
                for (let k in value.relationships.roles.data) {
                  if (value.relationships.roles.data[k].meta.drupal_internal__target_id === 'videostore') {
                    this.videostore = true;
                  }
                }
              }
              this._displayName = value.attributes.display_name;
              localStorage.setItem('display_name', value.attributes.display_name);
            }
            });

    }
  }

  get displayName() {
    return this._displayName;
  }
}

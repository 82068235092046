import { Component, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AuthService } from './services/auth.service';
import { FooldalService } from './services/fooldal.service';
import { UserService } from './services/user.service';
import { SharedService } from './services/shared.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']

})
export class AppComponent implements OnDestroy {
  title = 'Baba';

  menus: any[] = [];
  img: any[] = [];
  name: any[] = [];
  slogan: any[] = [];
  showNavbar: boolean = false;
  isNavbarCollapsed = false;
  faBars = faBars;
  isMenuOpen = false;
  showImage: boolean = true;
  spinner: boolean = false;
  username?: string;
  open: boolean = false;
  loggedUser: any;
  currentRoute?: string;
  private destroy$ = new Subject<void>();

  constructor(private fooldalService: FooldalService,
    private router: Router,
    private authService: AuthService,
    private sharedService: SharedService,
    private userService: UserService, private metaService: Meta) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        window.scrollTo(0, 0);
      }
    });

    this.metaService.addTags([
      {name: 'keywords', content: 'Termékenység, Petefészek, Méh, Meddőség, Lombik, Nőijóga, Hormon egyensúly, Ösztrogén dominancia, Ösztrogén, Progeszteron, PCO, Policisztás ovárium, IR, inzulinrezisztencia, Mióma, Petefészek kimerülés, Endometriózis, Pajzsmirigy alulműködés, IVF, Lombikprogram, mesterséges megtermékenyítés'}
    ]);


  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  onScroll() {
    this.showNavbar = window.scrollY > 0;
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showImage = this.router.url === '/fooldal';
      }
    });

    //menu
    this.fooldalService.getMenu().subscribe((linkSet) => {
      for (const [key, value] of Object.entries(linkSet)) {
        for (const key in value) {
          for (const k in value[key].item) {
            this.menus.push(value[key].item[k]);
          }
        }
      }
    });

    this.sharedService.spinner$.pipe(takeUntil(this.destroy$)).subscribe((spinner) => {
      this.spinner = spinner;
    });

    this.userService.loggedInUser$.pipe(takeUntil(this.destroy$)).subscribe(user => {
      this.loggedUser = user;
    });

    const isAuthenticated = this.authService.isAuthenticated();
    if (isAuthenticated) {
      this.loggedUser = JSON.parse(isAuthenticated);
    }
  }

  get isAdmin(): boolean {
    return this.authService.isAdmin();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getClassForRoute(route: any): string {
    if (route != "/admin/videotar") {
      return "max-width-600";
    }
    return "";
  }

  isNotFoundRoute(): boolean {

    return this.currentRoute !== '/not-found';

  }

}


<div class="container d-none d-xl-block">
<div class="wrapper ">
    <div class="arrow-steps clearfix">
        <div class="step hover" [routerLink]="'/fizetes'"> <span>Adatok</span> </div>
        <div class="step current"> <span>Összegzés</span> </div>
        <div class="step"> <span> Véglegesítés</span> </div>
    </div>
</div>
<div *ngFor="let profile of profileCustomer">
    <p class="cart-content">Kosár tartalma</p>
    <div class="mb-2" *ngFor="let product of toPayProduct">
        <span class="course-name">{{product.title}}</span>
        <span class="course-price">{{product.price}}</span>
    </div>
    <div *ngFor="let price of coupons" class="mb-2">
        <span class="course-name">{{price.name}}</span>
        <span class="course-price">{{price.price}}</span>
    </div>
    <div *ngFor="let price of cuoponPrice">
        <span class="course-name">Fizetendő</span>
        <span class="course-price">{{price.current}}</span>
    </div>
    <div class="line p-3"></div>
    <p class="cart-content">Számlázási adatok</p>
    <div style="padding-left:110px; width:900px; margin:35px auto">
    <div class="row mb-3">
      <div class="col-2">Név<sup>*</sup></div>
      <div class="col-5" style="padding-right:1rem  !important;"><input [formControl]="lastNameControl" type="text" style="width:100%;" [disabled]="true" /></div>
      <div class="col-5"><input [formControl]="firstNameControl" type="text" style="width: 100%;" [disabled]="true" /></div>
    </div>
    <div class="row mb-3">
      <div class="col-2">E-mail cím<sup>*</sup></div>
      <div class="col-10"><input [(ngModel)]="profile.email" style="width: 100%" type="text" disabled></div>
    </div>
    <div class="row mb-3">
      <div class="col-2">Cím<sup>*</sup></div>
      <div class="col-10" style="display:flex; flex-direction: column;">
        <select [(ngModel)]="profile.country" class="form-select form-control" style="width: 100%;" disabled>
          <option *ngFor="let country of countryList" [value]="country.key">{{country.value}}</option>
        </select>
        <div *ngIf="!profile.country" class="error-message">
          Ország megadása kötelező!
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-2"></div>
      <div class="col-5" style="display:flex; flex-direction: column;padding-right:1rem  !important;">
        <input [formControl]="postalCodeControl" required placeholder="Irányítószám" style="width:100%" type="text"/>
        <div *ngIf="postalCodeControl.errors?.['required'] && (postalCodeControl.dirty || postalCodeControl.touched)" class="error-message">
          Irányítószám megadása kötelező!
        </div>
      </div>
      <div class="col-5" style="display:flex; flex-direction: column;">
        <input [formControl]="cityControl" required placeholder="Város" type="text" style="width:100%" />
        <div *ngIf="cityControl.errors?.['required'] && (cityControl.dirty || cityControl.touched)" class="error-message">
          Város megadása kötelező!
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-2"></div>
      <div class="col-5" style="display:flex; flex-direction: column;padding-right:1rem  !important;">
        <input [formControl]="addressControl" required placeholder="Utca" type="text" style="width: 100%" />
        <div *ngIf="addressControl.errors?.['required'] && (addressControl.dirty || addressControl.touched)" class="error-message">
          Utca megadása kötelező!
        </div>
      </div>
      <div class="col-5" style="display:flex; flex-direction: column;">
        <input [formControl]="houseNumberControl" required placeholder="Házszám" type="text" style="width: 100%;" />
        <div *ngIf="houseNumberControl.errors?.['required'] && (houseNumberControl.dirty || houseNumberControl.touched)" class="error-message">
          Házszám megadása kötelező!
        </div>
      </div>
    </div>
    </div>
    <div class="line p-3"></div>
    <div class="container">
        <p class="cart-content">Fizetési mód</p>
        <div class="flex-container m-2 justify-content-center">
            <div id="simplepay" class="pink-circle" [ngClass]="{ 'pink-fill': isPinkFill1 }" (click)="toggleColors(1)" (click)="onSimplePayClick()"></div>
            <a href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank">
              <img src="assets/sp.png" style="height:100px;">
            </a>
        </div>
        <div class="flex-container m-2 justify-content-center">
            <div id="paypal" class="pink-circle" [ngClass]="{ 'pink-fill': isPinkFill2 }" (click)="toggleColors(2)" (click)="onPayPalClick()"></div>
            <img src="assets/pp.png" style="height:100px;">
        </div>
        <div class="d-flex flex-column m-2 justify-content-center" *ngIf="isPinkFill1">
          <p>
            Tudomásul veszem, hogy <b>Balogh Erika E.V.</b> (1024 Budapest Margit körút 25/B) adatkezelő által a
            www.babafeszkelo.hu weboldal felhasználói adatbázisában tárolt személyes
            adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére.
          </p>
          <p>
            Az adatkezelő által továbbított adatok köre az alábbi:
          </p>
            <ul>
              <li>Név</li>
              <li>Cím</li>
              <li>E-mail cím</li>
            </ul>
          <p>
            Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a
            SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg:
            <a style="text-decoration:underline; color:blue;" href="http://simplepay.hu/vasarlo-aff" target="_blank">http://simplepay.hu/vasarlo-aff</a>
          </p>
          <ng-container *ngIf="isRecurring">
            <p>
              Az ismétlődő bankkártyás fizetés (továbbiakban: „Ismétlődő fizetés”) egy, a SimplePay által
biztosított bankkártya elfogadáshoz tartozó funkció, mely azt jelenti, hogy a Vásárló által a
regisztrációs tranzakció során megadott bankkártyaadatokkal a jövőben újabb fizetéseket
lehet kezdeményezni a bankkártyaadatok újbóli megadása nélkül.
            </p>
  <p>
Az Ismétlődő fizetés igénybevételéhez jelen nyilatkozat elfogadásával Ön hozzájárul, hogy a
sikeres regisztrációs tranzakciót követően jelen webshopban (www.babafeszkelo.hu) a
kezdeményezett későbbi fizetések a bankkártyaadatok újbóli megadása és az Ön
tranzakciónként hozzájárulása nélkül a Kereskedő által kezdeményezve történjenek.
</p>
<p>
Figyelem(!): a bankkártyaadatok kezelése a kártyatársasági szabályoknak megfelelően
történik. A bankkártyaadatokhoz sem a Kereskedő, sem a SimplePay nem fér hozzá.
A Kereskedő által tévesen vagy jogtalanul kezdeményezett ismétlődő fizetéses tranzakciókért
közvetlenül a Kereskedő felel, Kereskedő fizetési szolgáltatójával (SimplePay) szemben
bármilyen igényérvényesítés kizárt.
</p>
<p>
Jelen tájékoztatót átolvastam, annak tartalmát tudomásul veszem és elfogadom.
            </p>
            <ul>
              <li>Automatikus fizetések maximális darabszáma: 24</li>
              <li>Automatikus fizetések maximális összege: {{toPayProduct[0].price}}</li>
              <li>Érvényesség: 24 hónap</li>
            </ul>
          </ng-container>
        </div>
    </div>

    <div class="d-flex justify-content-center">
        <button (click)="Pay()" class="btn btn-primary m-3">
          <ng-container *ngIf="isPinkFill1 && isRecurring">
            Kártyaregisztrációs fizetés
          </ng-container>
          <ng-container *ngIf="!isPinkFill1 || !isRecurring">
            Fizetés
          </ng-container>
          </button>
    </div>
</div>
</div>

<div class="container d-block d-xl-none" *ngIf="profileCustomer && profileCustomer[0]">
  <div class="d-flex flex-column arrow-steps">
      <div class="step"  [routerLink]="'/fizetes'"> <span>Adatok</span> </div>
      <div class="step current"> <span>Összegzés</span> </div>
      <div class="step"> <span> Véglegesítés</span> </div>
      <p class="cart-content" style="padding-left:0; margin: 1rem auto;" >Kosár tartalma</p>
      <div class="mb-2 d-flex justify-content-between" *ngFor="let product of toPayProduct">
        <span >{{product.title}}</span>
        <span >{{product.price}}</span>
      </div>
      <div *ngFor="let price of coupons" class="mb-2 d-flex justify-content-between">
        <span>{{price.name}}</span>
        <span>{{price.price}}</span>
      </div>
      <div *ngFor="let price of cuoponPrice" class="d-flex justify-content-between">
          <span>Fizetendő</span>
          <span>{{price.current}}</span>
      </div>
      <div class="line" style="width:100%"></div>
      <p class="cart-content" style="padding-left:0; margin: 1rem auto;" >Számlázási adatok</p>
      <div class="row mx-0 mb-3 gap-4 align-items-center">
        <div class="col-3">Név<sup>*</sup></div>
        <div class="col"><input [formControl]="lastNameControl" type="text" style="width:100%;" [disabled]="true" /></div>
        <div class="col"><input [formControl]="firstNameControl" type="text" style="width: 100%;" [disabled]="true" /></div>
      </div>
      <div class="row mx-0 mb-3 gap-4 align-items-center">
        <div class="col-3">E-mail cím<sup>*</sup></div>
        <div class="col"><input [(ngModel)]="profileCustomer[0].email" style="width: 100%" type="text" disabled></div>
      </div>
      <div class="row mx-0 mb-3 gap-4 align-items-center">
        <div class="col-3">Cím<sup>*</sup></div>
        <div class="col" style="display:flex; flex-direction: column;">
          <select [(ngModel)]="profileCustomer[0].country" class="form-select form-control" style="width: 100%;">
            <option *ngFor="let country of countryList" [value]="country.key">{{country.value}}</option>
          </select>
          <div *ngIf="!profileCustomer[0].country" class="error-message">
            Ország megadása kötelező!
          </div>
        </div>
      </div>

      <div class="row mx-0 mb-3 gap-4 align-items-center">
        <div class="col-3"></div>
        <div class="col" style="display:flex; flex-direction: column;">
          <input [formControl]="postalCodeControl" required placeholder="Irányítószám" style="width:100%" type="text"/>
          <div *ngIf="postalCodeControl.errors?.['required'] && (postalCodeControl.dirty || postalCodeControl.touched)" class="error-message">
            Irányítószám megadása kötelező!
          </div>
        </div>
        <div class="col" style="display:flex; flex-direction: column;">
          <input [formControl]="cityControl" required placeholder="Város" type="text" style="width:100%" />
          <div *ngIf="cityControl.errors?.['required'] && (cityControl.dirty || cityControl.touched)" class="error-message">
            Város megadása kötelező!
          </div>
        </div>
      </div>

      <div class="row mx-0 mb-3 gap-4 align-items-center">
        <div class="col-3"></div>
        <div class="col" style="display:flex; flex-direction: column;">
          <input [formControl]="addressControl" required placeholder="Utca" type="text" style="width: 100%" />
          <div *ngIf="addressControl.errors?.['required'] && (addressControl.dirty || addressControl.touched)" class="error-message">
            Utca megadása kötelező!
          </div>
        </div>
        <div class="col" style="display:flex; flex-direction: column;">
          <input [formControl]="houseNumberControl" required placeholder="Házszám" type="text" style="width: 100%;" />
          <div *ngIf="houseNumberControl.errors?.['required'] && (houseNumberControl.dirty || houseNumberControl.touched)" class="error-message">
            Házszám megadása kötelező!
          </div>
        </div>
      </div>

      <div class="line p-3" style="width:100%"></div>
        <p class="cart-content" style="padding-left:0; margin: 1rem auto;" >Fizetési mód</p>
          <div class="flex-container m-2 justify-content-center">
              <div id="simplepay" class="pink-circle" [ngClass]="{ 'pink-fill': isPinkFill1 }" (click)="toggleColors(1)" (click)="onSimplePayClick()"></div>
              <img src="assets/sp.png" style="height:100px;">
          </div>
          <div class="flex-container m-2 justify-content-center">
              <div id="paypal" class="pink-circle" [ngClass]="{ 'pink-fill': isPinkFill2 }" (click)="toggleColors(2)" (click)="onPayPalClick()"></div>
              <img src="assets/pp.png" style="height:100px;">
          </div>
          <div class="d-flex m-2 gap-2 justify-content-start" *ngIf="isPinkFill1">
            <div class="d-flex flex-column">
              <p>
                Tudomásul veszem, hogy <b>Balogh Erika E.V.</b> (1024 Budapest Margit körút 25/B) adatkezelő által a
                www.babafeszkelo.hu weboldal felhasználói adatbázisában tárolt személyes
                adataim átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére.
              </p>
              <p>
                Az adatkezelő által továbbított adatok köre az alábbi:
              </p>
              <ul>
                <li>Név</li>
                <li>Cím</li>
                <li>E-mail cím</li>
              </ul>
              <p>
                Az adatfeldolgozó által végzett adatfeldolgozási tevékenység jellege és célja a
                SimplePay Adatkezelési tájékoztatóban, az alábbi linken tekinthető meg:
                <a href="http://simplepay.hu/vasarlo-aff" target="_blank">http://simplepay.hu/vasarlo-aff</a>
              </p>
              <ng-container *ngIf="isRecurring">
                <p>
                  Az ismétlődő bankkártyás fizetés (továbbiakban: „Ismétlődő fizetés”) egy, a SimplePay által
biztosított bankkártya elfogadáshoz tartozó funkció, mely azt jelenti, hogy a Vásárló által a
regisztrációs tranzakció során megadott bankkártyaadatokkal a jövőben újabb fizetéseket
lehet kezdeményezni a bankkártyaadatok újbóli megadása nélkül.
<p>
Az Ismétlődő fizetés igénybevételéhez jelen nyilatkozat elfogadásával Ön hozzájárul, hogy a
sikeres regisztrációs tranzakciót követően jelen webshopban (www.babafeszkelo.hu) a
kezdeményezett későbbi fizetések a bankkártyaadatok újbóli megadása és az Ön
tranzakciónként hozzájárulása nélkül a Kereskedő által kezdeményezve történjenek.
</p>
Figyelem(!): a bankkártyaadatok kezelése a kártyatársasági szabályoknak megfelelően
történik. A bankkártyaadatokhoz sem a Kereskedő, sem a SimplePay nem fér hozzá.
A Kereskedő által tévesen vagy jogtalanul kezdeményezett ismétlődő fizetéses tranzakciókért
közvetlenül a Kereskedő felel, Kereskedő fizetési szolgáltatójával (SimplePay) szemben
bármilyen igényérvényesítés kizárt.
<p>
Jelen tájékoztatót átolvastam, annak tartalmát tudomásul veszem és elfogadom.
                </p>
                <ul>
                  <li>Automatikus fizetések maximális darabszáma: 24</li>
                  <li>Automatikus fizetések maximális összege: {{toPayProduct[0].price}}</li>
                  <li>Érvényesség: 24 hónap</li>
                </ul>
              </ng-container>
            </div>
          </div>
      <div class="d-flex justify-content-center">
          <button (click)="Pay()" class="btn btn-primary m-3">
            <ng-container *ngIf="isPinkFill1 && isRecurring">
              Kártyaregisztrációs fizetés
            </ng-container>
            <ng-container *ngIf="!isPinkFill1 || !isRecurring">
              Fizetés
            </ng-container>
          </button>
      </div>
  </div>
</div>

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { te } from 'date-fns/locale';
import { FooldalService } from 'src/app/services/fooldal.service';

@Component({
  selector: 'app-sikertelen-kurzus',
  templateUrl: './sikertelen-kurzus.component.html',
  styleUrls: ['./sikertelen-kurzus.component.css']
})
export class SikertelenKurzusComponent {
  toPayProduct: any[] = [];
  profileCustomer: any[] = [];
  text = 'Sikertelen fizetés, kérünk próbáld újra!';
  subText: string | null = null;
  simpleTransactionId: string | null = null;

  constructor(private fooldalService: FooldalService, private route: ActivatedRoute) { }

  ngOnInit() {
    const obj = {
      title: '',
      price: ''
    }
    if (localStorage.getItem('product')) {
      var current = JSON.parse(localStorage.getItem('product')!)
    }
    obj.title = current.title;
    obj.price = current.price;
    this.toPayProduct.push(obj)


    const simple = this.route.snapshot.queryParamMap.has('r');
    if(simple) {
      const r = this.route.snapshot.queryParamMap.get('r')!;
      const s = this.route.snapshot.queryParamMap.get('s')!;
      const response = JSON.parse(atob(r));

      if(response.e == 'timeout') {
        this.text = 'Időtúllépés';
      } else if(response.r == 5017) {
        this.text = 'Megszakított fizetés!';
      } else {
        this.text = 'Sikertelen tranzakció.';
        this.simpleTransactionId = response.t;
        this.subText = 'Kérjük, ellenőrizze a tranzakció során megadott adatok helyességét.Amennyiben minden adatot helyesen adott meg, a visszautasítás okának kivizsgálása érdekében kérjük, szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.';

      }
    }

  }


}

<div class="container-fluid header-shadow">
  <div class="row">
    <div id="imageContainer" class="text-center logo-bg">
      <a routerLink="/fooldal">
        <img class="menu-logo" src="assets/menulogo.png" alt="Kép megjelenítés">
      </a>
    </div>
  </div>

  <div class="row">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <button class="navbar-toggler ml-auto" type="button" data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navlink d-lg-none highlight" [routerLink]="'/elofizetes'" *ngIf="loggedUser">Előfizetéseim</a>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup" #navbarCollapse>
        <div class="navbar-nav mx-auto">
          <ng-container *ngFor="let menu of menus">
          <a class="navlink" [routerLink]="menu.href"
            [ngClass]="{'active': isActiveMenu(menu.href), 'active-login': loggedUser && menu.href === '/elofizetes' }">{{ loggedUser && menu.href === '/elofizetes' ? 'Előfizetéseim' : menu.title }}</a>
          </ng-container>
          <a class="navlink active-login" routerLink="/signin"
            *ngIf="!loggedUser">Bejelentkezés</a>
          <label class="dropdown" *ngIf="loggedUser">
            <div class="dd-button navlink">
              {{displayName}}
            </div>
            <input type="checkbox" class="dd-input" id="test">
            <ul class="dd-menu">
              <li routerLink="/update-profile">Saját adatok</li>
              <li *ngIf="isAdmin" routerLink="/admin/videotar">Videótár kezelés</li>
              <li *ngIf="!isAdmin && videostore" routerLink="/u/videotar_elofizetett">Videótár</li>
              <ng-container *ngFor="let enrolledC of enrolledCourse">
                <li *ngIf="!enrolledC.videostore" [routerLink]="'/kurzusok/' + enrolledC.title">{{enrolledC.title}}</li>
                <li *ngIf="enrolledC.videostore" [routerLink]="'/u/videotar_elofizetett'">{{enrolledC.title}}</li>
              </ng-container>
              <li [routerLink]="'/tutorial-video/'">Tutorial</li>
              <li (click)="Logout()">Kijelentkezés</li>
            </ul>
          </label>
        </div>
      </div>
    </nav>
  </div>
</div>
